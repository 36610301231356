








































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ADD_CONSERVATEE_PRIVATE_NUMBER } from '../../../store/actions';
import { AddPhoneNumber, ConservatorshipDetails } from '../../../types';
import { ApiResponse } from '@/components/types';

const Conservatorship = namespace('conservatorship');

@Component({})
export default class PrivateNumberAddDialog extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Conservatorship.Action(ADD_CONSERVATEE_PRIVATE_NUMBER) addNumber!: (params: AddPhoneNumber) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value: AddPhoneNumber = {
    conservatee_id: this.conservatorship.id,
    phone_number: this.conservatorship.conservatee.privateNumber || ''
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        conservatee_id: this.conservatorship.id,
        phone_number: this.conservatorship.conservatee.privateNumber || ''
      };

      return;
    }

    setTimeout(() => {
      // @ts-ignore
      this.$refs.form.reset();
      this.error = null;
    }, 200);
  }

  save() {
    this.error = null;

    return this.addNumber(this.value);
  }

  remove() {
    this.error = null;

    return this.addNumber({
      conservatee_id: this.conservatorship.id,
      phone_number: ''
    });
  }
}
